import { Inner } from "../Inner/inner.component";

export const About = () => {
  return (
    <Inner>
      <div className="section once-in">
        <h2>Coming soon...</h2>
      </div>
    </Inner>
  );
};
